.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  z-index: 1;
  width: 100%;
}

.text-animated {
  font-size: 8vw;
  opacity: 0;
  animation: fadeIn 4s ease-in-out forwards;
}

.text-price {
  font-size: 10vw;
  font-weight: bold;
  color: gold;
  opacity: 0;
  animation: fadeInPrice 4s ease-in-out 1s forwards; /* Délai pour apparaître après le texte principal */
  margin: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInPrice {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.birthday-carousel {
  filter: blur(5px); /* Ajoute un flou */
}
