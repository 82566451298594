.gif-container {
  position: absolute;
  width: 50vw; /* Les GIFs prennent 10% de la largeur de l'écran */
  height: auto; /* Maintient les proportions */
  z-index: 2;
  filter: blur(2px); /* Ajoute un flou */
}

.bike {
  top: 15%; /* Position en haut, 10% de l'écran */
  right: -50vw; /* Commence hors de l'écran à droite */
  animation: bike-animation 3s linear infinite; /* Défilement de droite à gauche */
}

.rollers {
  bottom: 10%; /* Position en bas, 10% de l'écran */
  left: -50vw; /* Commence hors de l'écran à gauche */
  animation: rollers-animation 3s linear infinite; /* Défilement de gauche à droite */
}

/* Animation du vélo (droite -> gauche) */
@keyframes bike-animation {
  0% {
    right: -10vw;
  }
  100% {
    right: 100vw; /* Sort complètement à gauche */
  }
}

/* Animation des rollers (gauche -> droite) */
@keyframes rollers-animation {
  0% {
    left: -10vw;
  }
  100% {
    left: 100vw; /* Sort complètement à droite */
  }
}
