.carousel {
  background: #000; /* Fond noir pour mettre en valeur les médias */
  width: 100%;
  margin: 20px auto;
}

.carousel-cell {
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-cell img,
.carousel-cell video {
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.carousel-cell img:hover,
.carousel-cell video:hover {
  transform: scale(1.05); /* Zoom léger au survol */
}
